<div class="layout-menu row justify-content-start">
    <div class="col-auto menuburger-section">
        <mat-icon mat-button (click)="sidenav.toggle()" class="menuburger" svgIcon="menuburger-white"></mat-icon>
    </div>
    <mat-divider class="d-none d-sm-block menu-divider" [vertical]=true></mat-divider>
    <div class="col menu-list">
        <div class="layout-menu-logo"><img class="img-logo" src="../../../assets/img/logo-white.png" /></div>
        <ul class="listing">
            <li (click)="sidenav.toggle()"><a routerLink="/about">À PROPOS</a></li>
            <li (click)="sidenav.toggle()"><a routerLink="/team">NOTRE ÉQUIPE</a></li>
            <li (click)="sidenav.toggle()"><a routerLink="/services">NOS SERVICES</a></li>
            <li (click)="sidenav.toggle()"><a routerLink="/contacts">NOUS JOINDRE</a></li>
        </ul>
    </div>
</div>