import { Component, OnInit } from '@angular/core';
import { ServicesModel } from '../services.model';

@Component({
  selector: 'app-assist',
  templateUrl: './assist.component.html',
  styleUrls: ['./assist.component.scss']
})
export class AssistComponent implements OnInit {

  public service: ServicesModel;

  constructor() {
    this.service = {
      title: 'Formation de chien d\'assistance Complice Canin',
      content: 'Depuis longtemps nous constatons les bienfaits de la zoothérapie en intervention psycho-sociale. Tout le monde bénéficie d’être réconforté lors de moments difficiles, encore mieux par un chien!'
    };
  }

  ngOnInit(): void {
  }
}
