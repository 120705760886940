<div class="row justify-content-lg-end footer-top-alignment">    
    <div class="col align-self-center footer-copyright-box-top d-none d-lg-block">CONCEPTION WEB PAR VANESSA PRONOVOST - INFOGRAPHISTE & ILLUSTRATRICE ©COMPLICE CANIN L'OBÉISSANCE ÉVOLUÉE 2019. TOUS DROITS RÉSERVÉS</div>    
    <div  class="col-lg-auto">    <app-options></app-options></div>
</div>
<div class="footer-links-box d-lg-none">
    <div class="row justify-content-center align-items-center footer-link"><a routerLink="/contacts">NOUS JOINDRE</a></div>
    <div class="row justify-content-center align-items-center">
        <div><a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/complicecanin/"><mat-icon class="footer-social-icon" svgIcon="facebook-white"></mat-icon></a></div>
        <div><a target="_blank" rel="noopener noreferrer" href="https://ca.linkedin.com/in/caroline-albert-07945ab0"><mat-icon class="footer-social-icon" svgIcon="linkedin-white"></mat-icon></a></div>
        <div><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCWMZy1636y_nURdZNISTCYw"><mat-icon class="footer-social-icon" svgIcon="youtube-white"></mat-icon></a></div>
    </div>
</div>
<div class="col-12 footer-copyright-box d-block d-lg-none">CONCEPTION WEB PAR VANESSA PRONOVOST - INFOGRAPHISTE & ILLUSTRATRICE ©COMPLICE CANIN L'OBÉISSANCE ÉVOLUÉE 2019. TOUS DROITS RÉSERVÉS</div>