<div class="body-layout row">
    <div class="col-12 col-xl-6 left-content">
        <div class="service-layout row align-items-end">
            <div>
                <div class="col-12 service-title">{{ service.title }}</div>
                <div class="col-12 col-xl-9 service-content">{{ service.content }}</div>
            </div>
        </div>
        <div class="left-layer"></div>
    </div>
    <div class="col-12 col-xl-6 service-layout right-content">
        <div class="top-content">Il sera plus efficace de débuter l’entraînement chez vous, sans distraction, afin de bien bâtir les commandes, 
            et ensuite ajuster progressivement le niveau de difficulté.</div>
        <mat-accordion [togglePosition]="'before'">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <div class="bullet-title">Consultation à domicile</div>
              </mat-expansion-panel-header>              
              <div class="bullet-title title-time">90 minutes</div>        
              <ul>
                  <li>Sélection d’un chiot chez l’éleveur/chien de refuge, nouvelle adoption, bon départ</li>
                  <li>Protocole d’entraînement pour corriger un problème de comportement</li>
                  <ul>
                      <li>Vous avez besoin d’aide pour corriger un mauvais comportement chez votre chien.</li>
                      <li>Sachez que les chiens de tous âges peuvent apprendre de nouveaux comportements et perdre leurs mauvaises habitudes même les plus ancrées.</li>
                  </ul>
                  <li>Accueil des visiteurs, sauts sur les gens, mordillage/poursuite, peur des étrangers, réactivité aux chiens, aboiements, agressivité, anxiété de séparation, vol d’objet, protection de ressources, mal des transports, difficultés lors de la marche en laisse, fugues, désensibilisation à la coupe de griffes ou manipulations toilettage et vétérinaire.</li>
                  <li>Évaluation comportementale complète (pour Vétérinaire, Assurances, Municipalité, Refuge, etc.)</li>
              </ul>
            </mat-expansion-panel>
          
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <div class="bullet-title">Plan chiots</div>
              </mat-expansion-panel-header>
              <div class="bullet-title title-time">5 séances x 1 heure</div>        
              <div class="bullet-header">Tout ce que vous devez enseigner à votre chiot pour qu’il devienne un excellent chien calme, équilibré et autonome</div>
              <ul>
                  <li>Bonne utilisation de la cage</li>
                  <li>Propreté</li>
                  <li>4 règles de base en entraînement</li>
                  <li>Enseignement du langage canin</li>
                  <li>Bien jouer avec son chiot</li>
                  <li>Désensibilisation au port du harnais et de la laisse</li>
                  <li>Désensibilisation aux manipulations corporelles</li>
                  <li>Gestion du mordillage, poursuites, sauts sur les gens</li>
                  <li>Création des commandes : ASSIS / TOUCHE PAS / DONNE (séquence de rapport d’objet) / BISOU / COUCHE / RESTE / VIENS</li>
                  <li>Apprentissage de la marche en laisse</li>
                  <li>Accueil des visiteurs</li>
              </ul>          
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <div class="bullet-title">Obéissance évoluée</div>
                </mat-expansion-panel-header>
                <div class="bullet-title title-time">5 séances x 1 heure</div> 
                <ul>
                    <li>Méthode d’entraînement au marqueur associatif (précision)</li>
                    <li>ASSIS / COUCHE / RESTE / VIENS avec distractions, sans laisse</li>
                    <li>Marche au pied avancé</li>
                    <li>Reste (Magic mat)</li>
                    <li>Protocole de relaxation</li>
                    <li>Jeux intellectuels avancés</li>
                </ul>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <div class="bullet-title">Réhabilitation/thérapie du comportement</div>
                </mat-expansion-panel-header>
                <div class="bullet-title prog-title-time">Programme sur mesure 5 séances x 1 heure</div> 
                <div class="bullet-header">Évaluation de la situation à domicile et élaboration d’un programme d’entraînement personnalisé selon vos besoins et vos objectifs</div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
</div>