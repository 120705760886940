import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Caroline Albert';

  constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {
    this.matIconRegistry.addSvgIcon('facebook', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icone-fb.svg'));
    this.matIconRegistry.addSvgIcon('linkedin', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icone-linkedin.svg'));
    this.matIconRegistry.addSvgIcon('youtube', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icone-youtube.svg'));
    this.matIconRegistry.addSvgIcon('menuburger', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu-burger.svg'));

    this.matIconRegistry.addSvgIcon('facebook-white',
    this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icone-fb-blanc.svg'));
    this.matIconRegistry.addSvgIcon('linkedin-white', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icone-linkedin-blanc.svg'));
    this.matIconRegistry.addSvgIcon('youtube-white', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/icone-youtube-blanc.svg'));
    this.matIconRegistry.addSvgIcon('menuburger-white', this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/icons/menu-burger-blanc.svg'));
  }
}
