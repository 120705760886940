import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ContactService {
    //private api = 'https://mailthis.to/alexandre.rioux@live.com'
    private api = './email.php?email';

    constructor(private http: HttpClient) {}

    PostMessage(input: any) {

        const httpOptions = {
            headers: new HttpHeaders({
              'Content-Type': 'application/json'
            })
          };

        return this.http.post(this.api, JSON.stringify(input), httpOptions).pipe(
            map(
                (response: any) => {
                    if (response) {
                        return response;
                    }
                },
                (error: any) => {
                    return error;
                }
            )
        )
    }
}