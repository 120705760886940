import { Component, OnInit } from '@angular/core';
import { ServicesModel } from '../services.model';

@Component({
  selector: 'app-advanced',
  templateUrl: './advanced.component.html',
  styleUrls: ['./advanced.component.scss']
})
export class AdvancedComponent implements OnInit {

  public service: ServicesModel;

  constructor() {
    this.service = {
      title: 'Cours avancés',
      content: 'Les sports canins vous intéressent? Freestyle, frisbee et plus encore. Les cours avancés sont pour vous!'
    };
  }

  ngOnInit(): void {
  }

}
