<div class="body-layout row">
    <div class="col-12 col-xl-6 left-content">
        <div class="service-layout row align-items-end">
            <div>
                <div class="col-12 service-title">{{ service.title }}</div>
                <div class="col-12 col-xl-9 service-content">{{ service.content }}</div>
            </div>
        </div>
        <div class="left-layer"></div>
    </div>
    <div class="col-12 col-xl-6 right-content service-layout">
        <div class="top-content">Vous avez envie de perfectionner vos connaissances dans l’entraînement canin de haut
            niveau? Vous aimeriez
            tester les différentes disciplines sportives pour mieux cibler les préférences et aptitudes de votre chien ?
            Le cours d’introduction aux sports canins est pour vous.</div>
        <div class="top-content">Introduction aux sports canins</div>
        <mat-accordion [togglePosition]="'before'">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <div class="bullet-title">Fitness</div>
                </mat-expansion-panel-header>
                <p>Le fitness favorise le renforcement musculaire, l'équilibre, la flexibilité, le cardio, le mental et
                    la proprioception.</p>
                <p>Peu importe le style de vie de votre chien, qu'il soit jeune ou plus vieux, qu'il pratique un sport
                    de performance ou qu'il soit un chien de famille, une base en fitness est nécessaire pour que votre
                    chien apprenne à contrôler son corps et à distribuer son poids également sur ses 4 pattes lors de
                    mouvement comme par exemple marcher, courir ou tourner. Le fitness est également un excellent moyen
                    pour prévenir les blessures.</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <div class="bullet-title">Treibball</div>
                </mat-expansion-panel-header>
                <p>Le Treibball est un sport canin d'origine allemande. Le but de ce sport est de commander son chien à
                    distance pour qu'il ramène de gros ballons d'entraînement dans un but. Le treibball est une parfaite
                    alternative pour tous les chiens de berger qui n'ont malheureusement pas l'occasion d'exercer leurs
                    compétences sur un troupeau de moutons.</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <div class="bullet-title">Freestyle</div>
                </mat-expansion-panel-header>
                <p>Le freestyle est une discipline canine dans laquelle le chien exécute des tours d'obéissance et de
                    fantaisie sur de la musique.</p>
                <p>Lors de cet atelier, nous apprendrons 3 nouveaux mouvements de fantaisie à votre chien.</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <div class="bullet-title">Frisbee</div>
                </mat-expansion-panel-header>
                <p>Le frisbee artistique est très spectaculaire. Il combine le freestyle à des lancers de frisbee. 
                    Dans cet atelier, je vous enseignerai toutes les bases fondamentales au développement de ce sport. 
                    Prendre, Lancer, rouler, attraper, jetter, prendre son élan et certaines commandes essentielles 
                    pour vous amuser et développer le plein potentiel de votre chien en frisbee.</p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <div class="bullet-title">Détection olfactive</div>
                </mat-expansion-panel-header>
                <p>Saviez-vous que le sens olfactif du chien est 100 millions de fois plus développé que le notre?</p>
                <p>Le chien utilise son organe voméronasal qui est situé au dessus du palais afin d'obtenir plus
                    d'informations sur les différentes odeurs biologiques, telles que les phéromones. Il a donc une
                    perception plus globale d'une odeur, impliquant émotions et instinct. Les chiens peuvent nous aider
                    à prévenir différents problèmes de santés tels que: allergie alimentaire, diabète, troubles
                    cardiaques, convulsions, épilepsie, crise d'anxiété, cancers, etc.</p>
                <p>Ils peuvent aussi nous aider à retrouver des personnes disparues, des animaux disparues, à détecter
                    la moisissure, les punaises de lit, etc.</p>
                <p>Lors de cet atelier, nous apprendrons à votre chien à : Sentir, discriminer et identifier une odeur.
                </p>
            </mat-expansion-panel>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <div class="bullet-title">Marche au pied parfaite (niveau compétition)</div>
                </mat-expansion-panel-header>
                <p>Que c’est beau de voir la complicité entre le chien et son guide, lorsque notre toutou est attentif 
                    à nos moindres mouvements et nous fixe dans les yeux en battant de la queue! Lors de cet atelier 
                    je vous enseignerai comment on bâtit une marche impeccable. Le positionnement, le contact visuel soutenu, 
                    les pivots, les tours à gauche et droite, les pas de côté, avancer, reculer!</p>
            </mat-expansion-panel>
        </mat-accordion>
        <div class="top-content">
            <div class="bullet-title">Options:</div>
            <ul>
                <li>5 cours x 1 heure</li>
                <li>3 cours x 30 min</li>
                <li>Privé </li>
                <li>Groupe de 4 participants chiens qualifiés</li>
            </ul>
        </div>
    </div>
</div>