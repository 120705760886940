import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss']
})
export class OptionsComponent implements OnInit {

  public routerUrl!: string;

  private services!: Array<{ description: any; url: string; }>;

  private serviceIndex = 0;

  constructor(private router: Router) {
    // tslint:disable-next-line: deprecation
    router.events.subscribe((r: any) => {
        this.routerUrl = this.router.url;
        this.serviceIndex = this.services.findIndex(t => t.url === this.routerUrl);
    });
}

  ngOnInit(): void {
    this.services = [
      {
          description: 'Cours à domicile',
          url: '/services/home'
      },
      {
          description: 'Cours avancés',
          url: '/services/advanced'
      },
      {
          description: 'Formation chien assistance',
          url: '/services/assist'
      },
      {
          description: 'Formation éducateur canin',
          url: '/services/trainer'
      }
  ];
  }

  public footerServicesLeft(): void {
    const previousServiceUrl = this.serviceIndex === 0 ?
        this.services[3].url : this.services[--this.serviceIndex].url;

    this.router.navigateByUrl(previousServiceUrl);
  }

  public footerServicesRight(): void {
      const previousServiceUrl = this.serviceIndex === 3 ?
          this.services[0].url : this.services[++this.serviceIndex].url;

      this.router.navigateByUrl(previousServiceUrl);
  }

}
