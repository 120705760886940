<div class="row about-layout">
    <div class="about-global-top col-12 col-lg-6">
        <div class="row align-items-end" style="padding-top: 10px; padding-bottom: 15px;">
            <div class="col-12 top-title-section">
                <span class="about-title">À propos</span>
            </div>
            <div class="col-12 about-title-details" style="padding-left:35px">Complice Canin</div>
        </div>
        <div class="row justify-content-center about-top">
            <div class="col-6 col-lg-12 col-xl-6 about-top-img-section">
                <img class="about-img" src="./../../assets/img/img-a-propos-2.jpg"/>
            </div>
            <div class="col-6 col-lg-12 col-xl-6 about-top-text-global-section">
                <div class="about-text-section xp-style">L’entreprise Complice Canin a été conçue en 2008 par l’éducatrice canine Caroline Albert.
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-6 about-layout-right">
        <div class="row">
            <div class="about-global-bottom col-12">
                <p>Suite à son travail dans une clinique vétérinaire pendant 5 ans, elle a reconnu le grand besoin de la clientèle ainsi que du personnel médical à avoir accès à de meilleures ressources en matière d’éducation canine et d’entraînement. Elle a donc choisi de poursuivre ses études en comportement canin auprès des meilleures écoles scientifiquement reconnues au Québec et aux États-Unis.
                </p>
                <p>Malgré ces nombreuses années d’expériences en consultations privées, en gestion de groupe en pension récréatives, en toilettage-comportemental et en réhabilitation de chiens abandonnés, Caroline poursuit sans cesse des formations continues dans l’entraînement de haut niveau en sports canins, dans le domaine de la psychologie humaine, la zoothérapie et des cours de perfectionnement dans l’entraînement de chiens d’assistance médicale.
                </p>
                <p>En 2015, Complice canin a trouvé son âme-sœur professionnelle auprès de la merveilleuse équipe multi-disciplinaire de Woouf. Ayant les mêmes valeurs et le même souci d’améliorer la santé globale du chien, nous unions nos forces afin d’offrir des services de grandes qualités à nos clients qui souhaitent harmoniser la santé physique et mentale de leur meilleur ami.
                </p>
                <p>Toute notre équipe a pour mission d’enseigner au public à quel point l’équilibre dans notre relation avec notre chien est le secret pour vivre heureux et optimiser le potentiel d’entraînement de notre chien.
                </p>
                <p>Merci de votre confiance pendant toutes ces années, grâce à vous tous, aujourd’hui nous pouvons vivre notre passion d’améliorer la qualité de vie des chiens et surtout nous spécialiser à former des chiens d’assistance qui améliorent le quotidien de gens dans le besoin.
                </p>            
            </div>
        </div>
    </div>
</div>