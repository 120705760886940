import { Component, OnInit } from '@angular/core';
import { ServicesModel } from '../services.model';

@Component({
  selector: 'app-trainer',
  templateUrl: './trainer.component.html',
  styleUrls: ['./trainer.component.scss']
})
export class TrainerComponent implements OnInit {

  public service: ServicesModel;

  constructor() {
    this.service = {
      title: 'Formation éducateurs canins',
      content: 'Suivez une conférence pour apprendre les techniques de l’entraînement positif'
    };
  }

  ngOnInit(): void {
  }

}
