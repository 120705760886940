<div class="row body-layout contact-left">
    <div class="col-12">
        <div class="row"><div class="col-12 contact-title">NOUS JOINDRE</div></div>
        <!-- form goes here -->
        <form [formGroup]="FormData" (ngSubmit)="onSubmit(FormData.value)">            
            <div class="row">
                <div class="col-12 field">
                    <label class="contact-label">NOM DU CHIEN</label>
                    <input matInput type="text" name="subject" formControlName="subject" class="form-control w-100">
                </div>
            </div>

            <div class="row">
                <div class="col-12 field">
                    <label class="contact-label">PRÉNOM*</label>
                    <input matInput type="text" name="firstname" formControlName="firstname" class="form-control w-100">
                </div>
            </div>

            <div class="row">
                <div class="col-12 field">
                    <label class="contact-label">NOM*</label>
                    <input matInput type="text" name="lastname" formControlName="lastname" class="form-control w-100">
                </div>
            </div>

            <div class="row">
                <div class="col-12 field">
                    <label class="contact-label">COURRIEL*</label>
                    <input matInput type="email" name="email" formControlName="email" class="form-control w-100">
                </div>
            </div>

            <div class="row">
                <div class="col-12 field">
                    <label class="contact-label">TÉLÉPHONE</label>
                    <input matInput type="text" name="phone" formControlName="phone" class="form-control w-100">
                </div>
            </div>

            <div class="row">
                <div class="col-12 field">
                    <label class="contact-label">MESSAGE</label>
                    <textarea class="form-control contact-textarea w-100" name="message" formControlName="message"></textarea>
                </div>
            </div>

            <div class="row justify-content-center">
                <button type="submit" class="col-6 col-sm-4 col-lg-2 submit-contact" [disabled]="!FormData.valid">ENVOYER</button>
            </div>
        </form>
        <div class="col-12 contact-phone">N'HÉSITEZ PAS À ME CONTACTER<div> AU 514 608-9050</div></div>
    </div>
</div>
