import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServicesComponent } from './services/services.component';
import { ProfileComponent } from './profile/profile.component';
import { TeamComponent } from './team/team.component';
import { ServiceComponent } from './services/service/service.component';
import { HomeComponent } from './services/home/home.component';
import { AdvancedComponent } from './services/advanced/advanced.component';
import { AssistComponent } from './services/assist/assist.component';
import { TrainerComponent } from './services/trainer/trainer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LandingComponent } from './landing/landing.component';
import { HeaderComponent } from './layout/header/header.component';
import { MenuComponent } from './layout/menu/menu.component';
import { FooterComponent } from './layout/footer/footer.component';
import { OptionsComponent } from './layout/options/options.component';
import { SocialComponent } from './layout/social/social.component';
import { ContactComponent } from './contact/contact.component';
import { ContactService } from './contact/contact.service';

@NgModule({
  declarations: [
    AppComponent,
    ServicesComponent,
    ProfileComponent,
    TeamComponent,
    ServiceComponent,
    HomeComponent,
    AdvancedComponent,
    AssistComponent,
    TrainerComponent,
    LandingComponent,
    HeaderComponent,
    MenuComponent,
    FooterComponent,
    OptionsComponent,
    SocialComponent,
    ContactComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    MatSidenavModule,
    MatIconModule,
    MatDividerModule,
    MatCardModule,
    HttpClientModule,
    FormsModule, 
    ReactiveFormsModule,
    ToastrModule.forRoot()
  ],
  providers: [ContactService],
  bootstrap: [AppComponent]
})
export class AppModule { }
