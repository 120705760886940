import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { ContactComponent } from './contact/contact.component';
import { LandingComponent } from './landing/landing.component';
import { ProfileComponent } from './profile/profile.component';
import { AdvancedComponent } from './services/advanced/advanced.component';
import { AssistComponent } from './services/assist/assist.component';
import { HomeComponent } from './services/home/home.component';
import { ServicesComponent } from './services/services.component';
import { TrainerComponent } from './services/trainer/trainer.component';
import { TeamComponent } from './team/team.component';

const routes: Routes = [
  {
    path: 'services',
    component: ServicesComponent
  },
  {
    path: 'services/advanced',
    component: AdvancedComponent
  },
  {
    path: 'services/home',
    component: HomeComponent
  },
  {
    path: 'services/assist',
    component: AssistComponent
  },
  {
    path: 'services/trainer',
    component: TrainerComponent
  },
  {
    path: 'contacts',
    component: ContactComponent
  },
  {
    path: 'team',
    component: TeamComponent
  },
  {
    path: 'about',
    component: ProfileComponent
  },
  {
    path: '**',
    redirectTo: '',
    component: LandingComponent
  }
];

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
