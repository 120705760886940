import { Component, OnInit } from '@angular/core';
import { ServicesModel } from '../services.model';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public service: ServicesModel;

  constructor() {
    this.service = {
      title: 'Cours à domicile',
      content: 'Les cours à domicile permettent de rencontrer votre chien dans l’environnement où il est le plus à l’aise et nous serons en mesure de mieux vous conseiller sur vos aménagements qui faciliteront l’apprentissage.'
    };
  }

  ngOnInit(): void {
  }

}
