<div class="body-layout services-link">
    <div class="row justify-content-center">
        <div class="col-12 col-xl-6 services-home">
            <a routerLink="/services/home">
                <app-service [title]="home.title" [content]="home.content"></app-service>
            </a>
        </div>
        <div class="col-12 col-xl-6 services-advanced">
            <a routerLink="/services/advanced">
                <app-service [title]="advanced.title" [content]="advanced.content"></app-service>
            </a>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-xl-6 services-assist">
            <a routerLink="/services/assist">
                <app-service [title]="assist.title" [content]="assist.content" id='assist'></app-service>
            </a>
        </div>
        <div class="col-12 col-xl-6 services-trainer">
            <a routerLink="/services/trainer">
                <app-service [title]="trainer.title" [content]="trainer.content"></app-service>
            </a>
        </div> 
    </div>
</div>