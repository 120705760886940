import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { NotificationService } from '../notification.service';
import { ContactService } from './contact.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  FormData: FormGroup;

  constructor(private builder: FormBuilder, private contact: ContactService, private notifyService : NotificationService) { 
    this.FormData = this.builder.group({
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      message: new FormControl(''),
      phone: new FormControl(''),
      subject: new FormControl('')
      })
  }

  ngOnInit() {
    
  }

  onSubmit(FormData: FormGroup) {
    console.log(FormData)
    this.contact.PostMessage(FormData)
    .subscribe((response: any) => {
      console.log(response)
      this.showToasterSuccess()
    }, (error: { responseText: any; }) => {
      console.warn(error.responseText)
      console.log({ error })
      this.showToasterError()
    })
    this.FormData.reset()
    }

  showToasterSuccess(){
      this.notifyService.showSuccess("Courriel envoyé avec succès!")
  }
  
  showToasterError(){
      this.notifyService.showError("Une erreur est survenue lors de l'envoie du courriel!")
  }
}
