<div class="body-layout row">
    <div class="col-12 col-xl-6 left-content">
        <div class="service-layout row align-items-end">
            <div>
                <div class="col-12 service-title">{{ service.title }}</div>
                <div class="col-12 service-content">{{ service.content }}</div>
            </div>
        </div>
        <div class="left-layer"></div>
    </div>
    <div class="col-12 col-xl-6 right-content service-layout">
        <div class="top-content">
            <mat-accordion [togglePosition]="'before'">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <div class="bullet-title">Chien d'assistance émotionnelle ou de zoothérapie</div>
                  </mat-expansion-panel-header>
                  <p>Un bon chien d’assistance émotionnelle est un chien pré-qualifié pour ses aptitudes sociales, pour sa
                    confiance et son équilibre physique, mental et émotionnel.</p>
                <p>Le travail de chien d’assistance émotionnelle est complexe et il est primordial de
                    bien préparer, outiller, entraîner et désensibiliser le chien pour assurer ses responsabilités.
                    Nous formerons le chien à se sentir confortable et à être en contrôle dans les situations les plus difficiles pour un animal.</p> 
                    <div class="top-content">
                        <div class="bullet-title2">Vous aimeriez former votre chien à vous assister dans votre travail de:</div>
                        <ul>
                            <li>Psychologue</li>
                            <li>Travailleur social</li>
                            <li>Intervenant </li>
                            <li>Médecine privée</li>
                            <li>Enseignant</li>
                            <li>Orthopédagogue</li>
                            <li>Éducateur spécialisé</li>
                            <li>Zoothérapeute</li>
                            <li>Autres métiers connexes</li>
                        </ul>
                    </div>
                </mat-expansion-panel>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                      <div class="bullet-title">Chien d'assistance médicale</div>
                    </mat-expansion-panel-header>
                    <p>Un chien d'assistance médicale peut grandement contribuer à améliorer la qualité de vie de son humain. 
                        Nous entraînons des chiens d'assistance médicale selon les besoins spécifiques de chaque bénéficiaire. 
                    <p>Un chien d'assistance Complice Canin est un chien entraîné par des méthodes positives scientifiquement reconnues.
                        Nous formons des chiens autonomes et équilibrés, qui ressentent les émotions de leur humain, qui réfléchissent et osent offrir les meilleurs comportements, ils adorent travailler et ils sont très motivés à le faire !
                        (Troubles psychiatriques, PTSD, diabète, épilepsie, handicap physique affectant la motricité, autisme, etc)</p>
                    <p>Étant donné la diversité des maladies des bénéficiaires, nous  suggérerons la meilleure race de chien pour le type de travail requis.
                        Nous choisissons nos chiens d'assistance auprès des meilleurs éleveurs au Canada et aux États-Unis.
                        Il est toutefois possible d'entraîner un chien de refuge ou votre propre chien, suite à une évaluation détaillée de son potentiel d'apprentissage.</p>
                    <p>La durée moyenne d'un entraînement pour chien d'assistance médicale est d'environ 3 à 4 mois une fois l'âge adulte atteint. 
                        Les chiots en formation demeurent avec leur entraîneur les 2 mois complets de leur période cruciale de socialisation soit 
                        de 2 à 4 mois. Ils sont ensuite confiés à la famille et poursuivent leur entraînement d'obéissance 1 fois par semaine 
                        jusqu'à l'âge adulte. Il sont ensuite prêts à suivre la formation complète et à obtenir leur Certification de chien 
                        d'assistance médicale vers l'âge de 18 mois.</p>  
                  </mat-expansion-panel>
                </mat-accordion>
        </div>
    </div>
</div>