<div class="row about-layout">
    <div class="about-global-top col-12 col-lg-6">
        <div class="row align-items-end" style="padding-top: 10px; padding-bottom: 15px;">
            <div class="col-12 top-title-section">
                <span class="about-title">Caroline Albert</span>
                <span class="about-title-details"> M.C.P., APDT, PPG</span>
            </div>
            <div class="col-12 about-title-details" style="padding-left:35px">ÉDUCATRICE CANINE PROFESIONNELLE</div>
        </div>
        <div class="row justify-content-center about-top">
            <div class="col-8 col-lg-12 col-xl-8 about-top-img-section">
                <img class="about-img" src="../../../assets/img/img-a-propos.jpg"/>
            </div>
            <div class="col-4 col-lg-12 col-xl-4 about-top-text-global-section">
                <div class="about-text-section">
                    <div class="xp-style">11 ANS</div>
                    <div class="xp-style-detail">D'EXPÉRIENCE</div>
                </div>
                <div class="about-text-section">
                    <div class="xp-style">12 000HR+</div>
                    <div class="xp-style-detail">COURS PRIVÉ</div>
                </div>
                <div class="about-text-section">
                    <div class="xp-style">5000HR+</div>
                    <div class="xp-style-detail">SUPERVISION</div>
                </div>
                <div class="about-text-section">
                    <div class="xp-style">5 ANS</div>
                    <div class="xp-style-detail">EN MILIEU VÉTÉRINAIRE</div>
                </div>
                <div class="team-left-section d-none d-sm-block d-xl-none">
                    <div class="team-title">Notre équipe</div>
                    <div class="team-about-info" (click)="selected = 'Caro'">
                        <mat-icon>keyboard_arrow_right</mat-icon>
                        <span>Caroline Albert</span>
                    </div>
                    <div class="team-about-info" (click)="selected = 'Marilou'">
                        <mat-icon>keyboard_arrow_right</mat-icon>
                        <span>Marilou Hamel</span>
                    </div>
                    <div class="team-about-info" (click)="selected = 'Ariane'">
                        <mat-icon>keyboard_arrow_right</mat-icon>
                        <span>Ariane Trudel</span>
                    </div>
                    <div class="team-about-info" (click)="selected = 'Myriam'">
                        <mat-icon>keyboard_arrow_right</mat-icon>
                        <span>Myriam Smith</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row d-block d-sm-none d-xl-block team-left-option">
            <div class="col-12 team-left-section">
                <div class="team-title">Notre équipe</div>
                <div class="team-about-info" (click)="selected = 'Caro'">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>Caroline Albert</span>
                </div>
                <div class="team-about-info" (click)="selected = 'Marilou'">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>Marilou Hamel</span>
                </div>
                <div class="team-about-info" (click)="selected = 'Ariane'">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>Ariane Trudel</span>
                </div>
                <div class="team-about-info" (click)="selected = 'Myriam'">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>Myriam Smith</span>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-6 about-layout-right">
        <div *ngIf="selected == 'Caro'">
            <div class="about-global-middle col-12">
                <div class="col-12 about-middle-title">SPÉCIALITÉ</div>
                <div class="col-12 about-middle-content"><mat-icon>keyboard_arrow_right</mat-icon>CHIOTS</div>
                <div class="col-12 about-middle-content"><mat-icon>keyboard_arrow_right</mat-icon>ENTRAÎNEMENT AU CLICKER</div>
                <div class="col-12 about-middle-content"><mat-icon>keyboard_arrow_right</mat-icon>PENSION ÉDUCATIVE</div>
                <div class="col-12 about-middle-content"><mat-icon>keyboard_arrow_right</mat-icon>SPORT CANIN (AGILITÉ, FREESTYLE, FRISBEE)</div>
            </div>
            <div class="about-global-bottom col-12">
                <div class="col-12 about-bottom-title">FORMATION</div>
                <div class="col-12 about-middle-bottom"><mat-icon>keyboard_arrow_right</mat-icon>Magister Cynodo professionnel, St-Hyacinthe, M.C.P</div>
                <div class="col-12 about-middle-bottom"><mat-icon>keyboard_arrow_right</mat-icon>Certification Association of Professionnal Dog Trainer, APDT</div>
                <div class="col-12 about-middle-bottom"><mat-icon>keyboard_arrow_right</mat-icon>Certification Pet Professionnal Guild Force-Free Dog Trainer, PPG</div>
                <div class="col-12 about-middle-bottom"><mat-icon>keyboard_arrow_right</mat-icon>Entraînement en Frisbee artistique, Emilie Ménard</div>
                <div class="col-12 about-middle-bottom"><mat-icon>keyboard_arrow_right</mat-icon>Entraînement en Fitness canin, Marie-Josée Tousignant</div>
                <div class="col-12 about-middle-bottom"><mat-icon>keyboard_arrow_right</mat-icon>Freestyle intermédiaire - novice, Zuzia Kubica</div>
                <div class="col-12 about-middle-bottom"><mat-icon>keyboard_arrow_right</mat-icon>Mécanique de l'entraînement, Jean Lessard</div>
                <div class="col-12 about-middle-bottom"><mat-icon>keyboard_arrow_right</mat-icon>Méthode Bridge and Target, Patrice Robert</div>
                <div class="col-12 about-middle-bottom"><mat-icon>keyboard_arrow_right</mat-icon>Réactivité et agressivité canine, Patrice Robert</div>
                <div class="col-12 about-middle-bottom"><mat-icon>keyboard_arrow_right</mat-icon>T-Touch, Maryse Perreault</div>
                <div class="col-12 about-middle-bottom"><mat-icon>keyboard_arrow_right</mat-icon>Le chien, émotions et interactions, Dr. Joël Dehasse</div>
                <div class="col-12 about-middle-bottom"><mat-icon>keyboard_arrow_right</mat-icon>L'évolution des loups aux chiens, Raymond Coppinger</div>
                <div class="col-12 about-middle-bottom"><mat-icon>keyboard_arrow_right</mat-icon>La consultaion simplifiée, Jean Lessard</div>
                <div class="col-12 about-middle-bottom"><mat-icon>keyboard_arrow_right</mat-icon>Gestion animalière, Johanne Tassé</div>
                <div class="col-12 about-middle-bottom"><mat-icon>keyboard_arrow_right</mat-icon>Entraînement, réhabilitation, Dr. Joël Dehasse</div>
                <div class="col-12 about-middle-bottom"><mat-icon>keyboard_arrow_right</mat-icon>Entraînement au clicker, Jacinthe Bouchard</div>                
            </div>
        </div>
        <div *ngIf="selected == 'Marilou'" class="team-right-section">
            <div class="row team-right-top">
                <div class="col-12 d-none d-lg-block team-title-right" style="padding-bottom: 20px;">Marilou Hamel</div>
                <div class="col-5 d-lg-none align-self-start team-title-right">
                    <div>Marilou</div><div>Hamel</div>
                <div class="team-right-content d-none d-sm-block d-lg-none">
                    <div class="col-12 formation-title">FORMATION</div>
                    <div class="col-12 formation-detail">
                        <mat-icon>keyboard_arrow_right</mat-icon>
                        <span>Formation en comportement canin avec Caroline Albert, Complice Canin</span>
                    </div>
                    <div class="col-12 formation-detail">
                        <mat-icon>keyboard_arrow_right</mat-icon>
                        <span>B.A.C en travail social</span>
                    </div>
                    <div class="col-12 formation-detail">
                        <mat-icon>keyboard_arrow_right</mat-icon>
                        <span>Enseignante d'équitation</span>
                    </div>
                    <div class="col-12 formation-detail">
                        <mat-icon>keyboard_arrow_right</mat-icon>
                        <span>Animatrice et intervenante au centre Gymno pour enfants en difficultés d'apprentissages</span>
                    </div>
                </div>
            </div>
                <div class="col-7 col-lg-12 align-self-center"><img class="team-img" src="../../../assets/img/img-equipe-marilou.jpg"/></div>
            </div>
            <div class="row team-right-content d-block d-sm-none d-lg-block">
                <div class="col-12 formation-title">FORMATION</div>
                <div class="col-12 formation-detail">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>Formation en comportement canin avec Caroline Albert, Complice Canin</span>
                </div>
                <div class="col-12 formation-detail">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>B.A.C en travail social</span>
                </div>
                <div class="col-12 formation-detail">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>Enseignante d'équitation</span>
                </div>
                <div class="col-12 formation-detail">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>Animatrice et intervenante au centre Gymno pour enfants en difficultés d'apprentissages</span>
                </div>
            </div>
        </div>
        <div *ngIf="selected == 'Ariane'" class="team-right-section">
            <div class="row team-right-top">
                <div class="col-12 d-none d-lg-block team-title-right">Ariane Trudel</div>
                <div class="col-12 d-none d-lg-block about-title-details-team">Éducatrice canine</div>
                <div class="col-5 d-lg-none align-self-start team-title-right">
                    <div>Ariane</div><div>Trudel</div>
                    <div class="about-title-details-team">Éducatrice canine</div>
                    <div class="team-right-content d-none d-sm-block d-lg-none">
                        <div class="col-12 formation-title">FORMATION</div>
                        <div class="col-12 formation-detail">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <span>Formation en comportement canin avec Caroline Albert, Complice Canin</span>
                        </div>
                        <div class="col-12 formation-detail">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <span>Formation en entraînement et en comportement canin (FECC) avec Jean Lessard</span>
                        </div>
                        <div class="col-12 formation-detail">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <span>Conférence en ligne « Stress vs anxiété vs réactivité » par Dr Joël Dehasse</span>
                        </div>
                        <div class="col-12 formation-detail">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <span>Séminaire – Concepts de conditionnements avancés & structure en entraînement avec Simonne Raffa</span>
                        </div>
                        <div class="col-12 formation-detail">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <span>Séminaire : Outils essentiels pour l’auto-contrôle, la marche en laisse & le rappel chez le chien prédateur avec Simonne Raffa (en voie d’obtention)</span>
                        </div>
                        <div class="col-12 formation-detail">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <span>Introduction au pistage avec Anne-Marie Giroux (en voie d’obtention)</span>
                        </div>
                        <div class="col-12 formation-detail">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <span>Introduction à la détection d’odeurs avec Anne-Marie Giroux (en voie d’obtention)</span>
                        </div>
                        <div class="col-12 formation-detail">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <span>Détection d’odeurs – Niveau intermédiaire avec Anne-Marie Giroux (en voie d’obtention)</span>
                        </div>
                        <div class="col-12 formation-detail">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <span>Certification Raw Dog Food Nutrition Specialist, DNM University</span>
                        </div>
                        <div class="col-12 formation-detail">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <span>Certification Pet Food Nutrition Specialist, DNM University</span>
                        </div>
                        <div class="col-12 formation-detail">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <span>Baccalauréat en psychologie à l’Université de Montréal (obtenu en 2019)</span>
                        </div>
                        <div class="col-12 formation-detail">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <span>Baccalauréat en travail social à l’Université du Québec en Outaouais (en voie d’obtention)</span>
                        </div>
                        <div class="col-12 formation-title">EXPÉRIENCES</div>
                        <div class="col-12 formation-detail">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <span>3 ans d’expérience comme conseillère en nutrition et bien-être canin chez Woouf Laval & Saint-Sauveur</span>
                        </div>
                        <div class="col-12 formation-detail">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <span>1 an d’expérience en pension canine</span>
                        </div>
                        <div class="col-12 formation-detail">
                            <mat-icon>keyboard_arrow_right</mat-icon>
                            <span>Expérience en supervision de groupes de chiens/chiots</span>
                        </div>
                    </div>
                </div>
                <div class="col-7 col-lg-12"><img class="team-img" src="../../../assets/img/img-equipe-ariane.jpg"/></div>
            </div>
            <div class="row team-right-content d-block d-sm-none d-lg-block">
                <div class="col-12 formation-title">FORMATION</div>
                <div class="col-12 formation-detail">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>Formation en comportement canin avec Caroline Albert, Complice Canin</span>
                </div>
                <div class="col-12 formation-detail">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>Formation en entraînement et en comportement canin (FECC) avec Jean Lessard</span>
                </div>
                <div class="col-12 formation-detail">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>Conférence en ligne « Stress vs anxiété vs réactivité » par Dr Joël Dehasse</span>
                </div>
                <div class="col-12 formation-detail">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>Séminaire – Concepts de conditionnements avancés & structure en entraînement avec Simonne Raffa</span>
                </div>
                <div class="col-12 formation-detail">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>Séminaire : Outils essentiels pour l’auto-contrôle, la marche en laisse & le rappel chez le chien prédateur avec Simonne Raffa (en voie d’obtention)</span>
                </div>
                <div class="col-12 formation-detail">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>Introduction au pistage avec Anne-Marie Giroux (en voie d’obtention)</span>
                </div>
                <div class="col-12 formation-detail">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>Introduction à la détection d’odeurs avec Anne-Marie Giroux (en voie d’obtention)</span>
                </div>
                <div class="col-12 formation-detail">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>Détection d’odeurs – Niveau intermédiaire avec Anne-Marie Giroux (en voie d’obtention)</span>
                </div>
                <div class="col-12 formation-detail">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>Certification Raw Dog Food Nutrition Specialist, DNM University</span>
                </div>
                <div class="col-12 formation-detail">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>Certification Pet Food Nutrition Specialist, DNM University</span>
                </div>
                <div class="col-12 formation-detail">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>Baccalauréat en psychologie à l’Université de Montréal (obtenu en 2019)</span>
                </div>
                <div class="col-12 formation-detail">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>Baccalauréat en travail social à l’Université du Québec en Outaouais (en voie d’obtention)</span>
                </div>
                <div class="col-12 formation-title">EXPÉRIENCES</div>
                <div class="col-12 formation-detail">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>3 ans d’expérience comme conseillère en nutrition et bien-être canin chez Woouf Laval & Saint-Sauveur</span>
                </div>
                <div class="col-12 formation-detail">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>1 an d’expérience en pension canine</span>
                </div>
                <div class="col-12 formation-detail">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>Expérience en supervision de groupes de chiens/chiots</span>
                </div>
            </div>
        </div>
        <div *ngIf="selected == 'Myriam'" class="team-right-section">
            <div class="row team-right-top">
                <div class="col-12 d-none d-lg-block team-title-right">Myriam Smith</div>
                <div class="col-12 d-none d-lg-block about-title-details-team">Éducatrice canine</div>
                <div class="col-5 d-lg-none align-self-start team-title-right">
                    <div>Myriam</div><div>Smith</div>
                    <div class="about-title-details-team">Éducatrice canine</div>
                <div class="team-right-content d-none d-sm-block d-lg-none">
                    <div class="col-12 formation-title">FORMATION</div>
                    <div class="col-12 formation-detail">
                        <mat-icon>keyboard_arrow_right</mat-icon>
                        <span>Formation d'éducateur canin Complice Canin, avec Caroline Albert</span>
                    </div>
                    <div class="col-12 formation-detail">
                        <mat-icon>keyboard_arrow_right</mat-icon>
                        <span>Techniques de marche au pied avancées, avec Caroline Albert</span>
                    </div>
                    <div class="col-12 formation-detail">
                        <mat-icon>keyboard_arrow_right</mat-icon>
                        <span>Promenades en laisse zen, De main de maître</span>
                    </div>
                    <div class="col-12 formation-detail">
                        <mat-icon>keyboard_arrow_right</mat-icon>
                        <span>Rappel express 2.0, De main de maître</span>
                    </div>
                    <div class="col-12 formation-detail">
                        <mat-icon>keyboard_arrow_right</mat-icon>
                        <span>Gestion et réhabilitation des chiens réactifs, avec Caroline Albert</span>
                    </div>
                    <div class="col-12 formation-detail">
                        <mat-icon>keyboard_arrow_right</mat-icon>
                        <span>Boîte à outils pour le chien énergique, De main de maître</span>
                    </div>
                    <div class="col-12 formation-detail">
                        <mat-icon>keyboard_arrow_right</mat-icon>
                        <span>Toilettage comportemental Complice Canin, avec Caroline Albert</span>
                    </div>
                </div>
            </div>
                <div class="col-7 col-lg-12"><img class="team-img" src="../../../assets/img/img-equipe-myriam.jpeg"/></div>
            </div>
            <div class="row team-right-content d-block d-sm-none d-lg-block">
                <div class="col-12 formation-title">FORMATION</div>
                <div class="col-12 formation-detail">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>Formation d'éducateur canin Complice Canin, avec Caroline Albert</span>
                </div>
                <div class="col-12 formation-detail">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>Techniques de marche au pied avancées, avec Caroline Albert</span>
                </div>
                <div class="col-12 formation-detail">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>Promenades en laisse zen, De main de maître</span>
                </div>
                <div class="col-12 formation-detail">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>Rappel express 2.0, De main de maître</span>
                </div>
                <div class="col-12 formation-detail">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>Gestion et réhabilitation des chiens réactifs, avec Caroline Albert</span>
                </div>
                <div class="col-12 formation-detail">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>Boîte à outils pour le chien énergique, De main de maître</span>
                </div>
                <div class="col-12 formation-detail">
                    <mat-icon>keyboard_arrow_right</mat-icon>
                    <span>Toilettage comportemental Complice Canin, avec Caroline Albert</span>
                </div>
            </div>
        </div>
    </div>
</div>