<div class="body-layout landing-main row justify-content-center h-100">
    <div class="left-landing-content col-12 col-xl-6">
        <div class="h-100 d-flex flex-column">
            <div class="row justify-content-center align-items-center flex-grow-1">
                <a routerLink="/services/home">
                    <div class="landing-content">COURS À DOMICILE</div>
                    <div class="left-layer"></div>
                </a>
            </div>
        </div>
    </div>
    <div class="right-landing-content col-12 col-xl-6">
        <div class="h-100 d-flex flex-column">
            <div class="row justify-content-center align-items-center flex-grow-1">
                <a routerLink="/services/advanced">
                    <div class="landing-content">COURS AVANCÉS</div>
                    <div class="right-layer"></div>
                </a>
            </div>
        </div>
    </div>
</div>