import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-service',
  templateUrl: './service.component.html',
  styleUrls: ['./service.component.scss']
})
export class ServiceComponent implements OnInit {

  @Input() id: string;
  @Input() title: string;
  @Input() content: string;

  constructor() {
    this.id = '';
    this.title = '';
    this.content = '';
  }

  ngOnInit(): void {
  }

}
