<mat-sidenav-container>
  <mat-sidenav #sidenav class="side-nav">
    <app-menu [sidenav]="sidenav"></app-menu>
  </mat-sidenav>

  <mat-sidenav-content class="overwrite-drawer">
      <app-header [sidenav]="sidenav"></app-header>
      <div class="content">
        <router-outlet></router-outlet>
      </div>
      <app-footer></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>