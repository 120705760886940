import { Component, OnInit } from '@angular/core';
import { ServicesModel } from './services.model';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  home: ServicesModel;
  advanced: ServicesModel;
  assist: ServicesModel;
  trainer: ServicesModel;

  constructor() {
    this.home = {
      title: 'Cours à domicile',
      content: 'Les cours à domicile permettent de rencontrer votre chien dans l’environnement où il est le plus à l’aise et nous serons en mesure de mieux vous conseiller sur vos aménagements qui faciliteront l’apprentissage.'
    };

    this.advanced = {
      title: 'Cours avancés',
      content: 'Les sports canins vous intéressent? Freestyle, frisbee et plus encore. Les cours avancés sont pour vous!'
    };

    this.assist = {
      title: 'Formation de chien d\'assistance Complice Canin',
      content: 'Depuis longtemps nous constatons les bienfaits de la zoothérapie en intervention psycho-sociale. Tout le monde bénéficie d’être réconforter lors de moments difficiles, encore mieux par un chien!'
    };

    this.trainer = {
      title: 'Formation éducateurs canins',
      content: 'Suivez une conférence pour apprendre les techniques de l’entraînement positif'
    };
  }

  ngOnInit(): void { }
}
