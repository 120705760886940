<div class="body-layout row">
    <div class="col-12 col-xl-6 left-content">
        <div class="service-layout row align-items-end">
            <div class="service-section-top-layout">
                <div class="col-12 service-title">{{ service.title }}</div>
                <div class="col-12 col-xl-9 service-content">{{ service.content }}</div>
            </div>
        </div>
        <div class="left-layer"></div>
    </div>
    <div class="col-12 col-xl-6 right-content service-layout">
        <div class="top-content">
            <p>Nous recrutons présentement des éducateurs canins partout au Québec et au Canada et nous offrons la formation continue.</p>
        <div class="bullet-title">Vous aimeriez devenir éducateur canin et faire partie de notre équipe?</div> 
            <p>Suite à des tests d'évaluation de vos connaissances actuelles, je vous proposerai un programme de formation privé qui vous permettra de concilier la théorie et la pratique sur le terrain rapidement, peut importe votre lieu de résidence. Il est possible pour mes étudiants de m'accompagner dans le quotidien du métier jusqu'à l'obtention du diplôme final. Vous pourrez ensuite travailler en collaboration avec notre équipe et un secteur géographique vous sera confié.</p>
        </div>
    </div>
</div>